import React, { useState, useEffect } from "react";
import {
	DatePicker,
	Card,
	Statistic,
	Row,
	Col,
	Select,
	message,
	List,
	Spin,
} from "antd";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { getUsers, getUserSourceStatistics, fetchDatabases } from "../../services/apiMethods";

ChartJS.register(ArcElement, Tooltip, Legend);

const { Option } = Select;

const STATUS_COLORS = {
	Новый: "#FF6384",
	Ребенок: "#36A2EB",
	НБТ: "#FFCE56",
	"Нет средств": "#AAFF56",
	Умник: "#FF9AA2",
	Срез: "#B39CD0",
	Перезвон: "#FDFD96",
	Поляк: "#77DD77",
	Списали: "#AEC6CF",
	"Срез на закрытии": "#CFCFC4",
	Закрыт: "#FFB347",
};

const getStatusColor = (status) => STATUS_COLORS[status] || "#CCCCCC";

const LeadSourceStatistics = () => {
	const [userStats, setUserStats] = useState({});
	const [users, setUsers] = useState([]);
	const [sources, setSources] = useState([]);
	const [selectedUser, setSelectedUser] = useState("");
	const [selectedSource, setSelectedSource] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [loadingUsers, setLoadingUsers] = useState(true);
	const [loadingSources, setLoadingSources] = useState(false);
	const [loadingStats, setLoadingStats] = useState(false);

	useEffect(() => {
		fetchUsers();
	}, []);

	useEffect(() => {
		if (selectedUser) {
			fetchSources();
		}
	}, [selectedUser]);

	useEffect(() => {
		if (selectedUser && selectedSource) {
			fetchStatistics();
		}
	}, [selectedUser, selectedSource, startDate, endDate]);

	const fetchUsers = async () => {
		setLoadingUsers(true);
		try {
			const allUsers = await getUsers();
			setUsers(allUsers);
			if (allUsers.length > 0) {
				setSelectedUser(allUsers[0].username);
			}
		} catch (error) {
			message.error("Ошибка при загрузке списка пользователей");
		} finally {
			setLoadingUsers(false);
		}
	};

	const fetchSources = async () => {
		if (!selectedUser) return;

		setLoadingSources(true);
		try {
			const userSources = await fetchDatabases(selectedUser);
			setSources(userSources);
			setSelectedSource(userSources.length > 0 ? userSources[0] : "");
		} catch (error) {
			message.error("Ошибка при загрузке списка источников");
		} finally {
			setLoadingSources(false);
		}
	};

	const fetchStatistics = async () => {
		if (!selectedUser || !selectedSource) return;

		setLoadingStats(true);
		try {
			const stats = await getUserSourceStatistics(
				selectedUser,
				startDate ? startDate.format("YYYY-MM-DD") : undefined,
				endDate ? endDate.format("YYYY-MM-DD") : undefined,
				selectedSource
			);
			setUserStats(stats.sourceStats);
		} catch (error) {
			message.error("Ошибка при загрузке статистики пользователя");
		} finally {
			setLoadingStats(false);
		}
	};

	const totalLeads = selectedSource && userStats[selectedSource]
		? userStats[selectedSource].reduce((sum, stat) => sum + stat.count, 0)
		: 0;

	const pieData = {
		labels: userStats[selectedSource]?.map((stat) => stat.statusName) || [],
		datasets: [
			{
				data: userStats[selectedSource]?.map((stat) => stat.count) || [],
				backgroundColor: userStats[selectedSource]?.map((stat) =>
					getStatusColor(stat.statusName)
				) || [],
			},
		],
	};

	return (
		<div>
			<h2>Статистика по источникам лидов (Пользователь)</h2>
			<Row gutter={16} style={{ marginBottom: 20 }}>
				<Col span={6}>
					<Select value={selectedUser} onChange={setSelectedUser} style={{ width: "100%" }}>
						{users.map((u) => (
							<Option key={u.id} value={u.username}>
								{u.username}
							</Option>
						))}
					</Select>
				</Col>
				<Col span={6}>
					<Select value={selectedSource} onChange={setSelectedSource} style={{ width: "100%" }}>
						{sources.map((src) => (
							<Option key={src} value={src}>
								{src}
							</Option>
						))}
					</Select>
				</Col>
			</Row>

			{loadingStats ? (
				<Spin size="large" />
			) : (
				<Row gutter={16}>
					<Col span={12}>
						<Card title="Общая статистика">
							<p>Прозвоненные: {totalLeads} лидов</p>
						</Card>
					</Col>
					<Col span={12}>
						<Card title="Диаграмма статусов">
							<Pie data={pieData} />
						</Card>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default LeadSourceStatistics;
