import React, { useState, useEffect } from "react";
import {
	Card,
	Row,
	Col,
	Select,
	DatePicker,
	message,
	Spin,
} from "antd";
import { Pie } from "react-chartjs-2";
import { getTeams, getTeamSourceStatistics, fetchDatabases } from "../../services/apiMethods";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const { Option } = Select;

const ALL_STATUSES = [
	"Новый",
	"Ребенок",
	"НБТ",
	"Нет средств",
	"Умник",
	"Срез",
	"Перезвон",
	"Поляк",
	"Списали",
	"Срез на закрытии",
	"Закрыт",
];

const STATUS_COLORS = {
	Новый: "#FF6384",
	Ребенок: "#36A2EB",
	НБТ: "#FFCE56",
	"Нет средств": "#AAFF56",
	Умник: "#FF9AA2",
	Срез: "#B39CD0",
	Перезвон: "#FDFD96",
	Поляк: "#77DD77",
	Списали: "#AEC6CF",
	"Срез на закрытии": "#CFCFC4",
	Закрыт: "#FFB347",
};

const getStatusColor = (status) => STATUS_COLORS[status] || "#CCCCCC";

const TeamSourceStatistics = () => {
	const [teamStats, setTeamStats] = useState({});
	const [teams, setTeams] = useState([]);
	const [sources, setSources] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [selectedSource, setSelectedSource] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [loadingTeams, setLoadingTeams] = useState(true);
	const [loadingSources, setLoadingSources] = useState(false);
	const [loadingStats, setLoadingStats] = useState(false);

	useEffect(() => {
		fetchUserAndTeams();
	}, []);

	useEffect(() => {
		if (selectedTeam) {
			fetchSources();
		}
	}, [selectedTeam]);

	useEffect(() => {
		if (selectedTeam && selectedSource) {
			fetchTeamStatistics();
		}
	}, [selectedTeam, selectedSource, startDate, endDate]);

	const fetchUserAndTeams = async () => {
		setLoadingTeams(true);
		try {
			const storedUser = JSON.parse(localStorage.getItem("user"));
			if (!storedUser) {
				message.error("Ошибка: пользователь не найден.");
				return;
			}

			const allTeams = await getTeams();
			const filteredTeams =
				storedUser.role === "closer"
					? allTeams.filter((team) => team.id === storedUser.teamId)
					: allTeams;

			setTeams(filteredTeams);

			if (filteredTeams.length > 0) {
				setSelectedTeam(filteredTeams[0].name);
			}
		} catch (error) {
			message.error("Ошибка при загрузке команд");
		} finally {
			setLoadingTeams(false);
		}
	};

	const fetchSources = async () => {
		if (!selectedTeam) return;

		setLoadingSources(true);
		try {
			const teamSources = await fetchDatabases(selectedTeam);
			setSources(teamSources);
			setSelectedSource(teamSources.length > 0 ? teamSources[0] : "");
		} catch (error) {
			message.error("Ошибка при загрузке списка источников");
		} finally {
			setLoadingSources(false);
		}
	};

	const fetchTeamStatistics = async () => {
		if (!selectedTeam || !selectedSource) return;

		setLoadingStats(true);
		try {
			const stats = await getTeamSourceStatistics(
				selectedTeam,
				startDate ? startDate.format("YYYY-MM-DD") : undefined,
				endDate ? endDate.format("YYYY-MM-DD") : undefined,
				selectedSource
			);

			setTeamStats(stats.sourceStats);
		} catch (error) {
			message.error("Ошибка при загрузке статистики команды");
		} finally {
			setLoadingStats(false);
		}
	};

	const totalLeads =
		selectedSource && teamStats[selectedSource]
			? teamStats[selectedSource].reduce((sum, stat) => sum + stat.count, 0)
			: 0;

	const filteredStats = selectedSource && teamStats[selectedSource]
		? ALL_STATUSES.map((status) => {
				const existingStatus = teamStats[selectedSource].find(
					(s) => s.statusName === status
				);
				return {
					status,
					count: existingStatus ? existingStatus.count : 0,
				};
		  }).filter((stat) => stat.status !== "Новый")
		: [];

	const data = {
		labels: filteredStats.map((item) => item.status),
		datasets: [
			{
				data: filteredStats.map((item) => item.count),
				backgroundColor: filteredStats.map((item) => getStatusColor(item.status)),
			},
		],
	};

	return (
		<div>
			<h2>Статистика по источникам лидов (Команда)</h2>
			<Row gutter={16} style={{ marginBottom: 20 }}>
				<Col span={8}>
					<Select value={selectedTeam} onChange={setSelectedTeam} style={{ width: "100%" }}>
						{teams.map((team) => (
							<Option key={team.id} value={team.name}>
								{team.name}
							</Option>
						))}
					</Select>
				</Col>
				<Col span={8}>
					<Select value={selectedSource} onChange={setSelectedSource} style={{ width: "100%" }}>
						{sources.map((src) => (
							<Option key={src} value={src}>
								{src}
							</Option>
						))}
					</Select>
				</Col>
			</Row>

			{loadingStats ? (
				<Spin size="large" />
			) : (
				<Row gutter={16}>
					<Col span={12}>
						<Card title="Общая статистика">
							<p>Прозвоненные: {totalLeads} лидов</p>
							{filteredStats.map((stat, index) => (
								<p
									key={index}
									style={{ display: "flex", alignItems: "center" }}
								>
									<span
										style={{
											display: "inline-block",
											width: 12,
											height: 12,
											borderRadius: "50%",
											backgroundColor: getStatusColor(stat.status),
											marginRight: 8,
										}}
									></span>
									{stat.status}: {stat.count} (
									{totalLeads > 0
										? ((stat.count / totalLeads) * 100).toFixed(2)
										: 0}
									%)
								</p>
							))}
						</Card>
					</Col>
					<Col span={12}>
						<Card title="Диаграмма статусов">
							<Pie data={data} />
						</Card>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default TeamSourceStatistics;
